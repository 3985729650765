import * as React from "react";
import "../../styles/reset.css";
import GlobalStyles from "../../styles/GlobalStyles";
import Footer from "../Footer";
import Header from "../Header";
import Hero from "../Hero";
// Import Styled Components Global
// Import SEO

const Layout = ({ children, className, location, ...props }) => {
  const pageGroup = props.pageContext?.group || null;
  const pagePath = location.pathname !== "/" ? location.pathname : "home";

  const isService = props.pathContext.group === "servicio";
  const isPost = props.pathContext.group === "blog";
  const isBlog = pagePath === "/blog" || pagePath === "/blog/";
  const isProyectos = pagePath === "/nosotros" || pagePath === "/nosotros/";
  const imageUrl = isService
    ? props.data.service.image.asset.url
    : isPost
    ? props.data.post.image.asset.url
    : isProyectos
    ? "https://images.unsplash.com/photo-1575800135090-344c21dcf361?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3133&q=80"
    : null;

  return (
    <>
      <GlobalStyles />
      <Header activePage={pageGroup || pagePath} isBlog={isBlog || isPost} />
      <Hero
        activePage={pageGroup || pagePath}
        data={props?.data}
        image={imageUrl}
        isProyectos={isProyectos}
        isPost={isPost}
      />
      {children}
      <Footer />
      {/* SEO */}
    </>
  );
};

export default Layout;
