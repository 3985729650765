import { createGlobalStyle, css } from "styled-components";
import { mediaQuery } from "./mixins";

// #384647

const GlobalStyles = createGlobalStyle`
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap'); */

  :root {
    --primary: #264653;
    --primaryLight: #2a9d8f;
    --secondary: #e2b33c;
    --secondaryLight: #05752E; 
    ${"" /* --secondaryLight: #e9c46a; */}
    --lightGrey: #f2f2f2;
    --midGrey: #e0e0e0;
    --midDarkGrey: #bdbdbd;
    --darkGrey: #828282;
    --danger: #c74817;
    --font-primary: "Josefin Sans",sans-serif;
    --font-secondary: "Caveat",cursive;
    --font-system: system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif;
    --borderRadius: 20px;

    // COLORS
    --color__primary: #377d4f;
    --color__primary-dark: #3d4a3d;
    --color__primary-mid: #828282;
    --color__primary-light: #c1c7cb;
    --color__primary-alt: #a1afa0;

    --color__secondary: #185464;
    --color__secondary-dark: #2f4858;
    --color__secondary-mid: #677798;
    --color__secondary-light: #e0f1ff;
    --color__secondary-alt: #8685ef;

    --color__black: #000000;
    --color__dark: #2d3142;
    --color__grey: #5b5f72;
    --color__light: #e8efff;
    --color__white: #ffffff;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    color: var(--color__dark);
    
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .section {
  width: 100%;
  padding: 3vw 0;

  ${mediaQuery.lg`
      padding: 90px 60px 140px;
  `}
}
`;
export const container = css`
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
`;

export default GlobalStyles;
