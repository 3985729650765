import styled, { css } from "styled-components";
import Logo from "../UI/Logo";
import { Link } from "gatsby";

import { container } from "../../styles/GlobalStyles";
import { mediaQuery } from "../../styles/mixins";

// LINKS
const linkBase = css`
  font-weight: 400;
  display: block;
  width: 80%;
  font-family: "Inter", sans-serif;
  font-family: "Open Sans", -apple-system, "system-ui", "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  transition: all 0.5s ease;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
  &:active {
    opacity: 0.7;
  }
`;

export const LinkStyled = styled(Link)`
  ${linkBase}
  font-size: 18px;
  color: #fff;
  opacity: 0.8;
  line-height: 2;

  ${mediaQuery.sm`
  line-height: 1.7;
  `}
`;

export const ServiceLink = styled(Link)`
  ${linkBase}
  opacity: 0.5;
  font-size: 16.5px;

  margin-left: 12px;
  line-height: 2.25;

  ${mediaQuery.sm`
    line-height: 2;
    margin-left: 0;

    &:hover {
      margin-left: 8px;
    }
  `}
`;

export const FooterStyles = styled.footer`
  position: relative;
  > div {
    ${container};
  }

  padding: 3rem 0 calc(3rem + 65px);
  background: #2c3143;
  color: #fff;

  .footer_wrapper {
    display: flex;
    flex-direction: column;
    gap: 18px;

    ${mediaQuery.sm`
      flex-direction: row;
      gap: 0;
  `}
  }

  .footer_links {
    width: 100%;
    display: flex;
    flex-direction: column;

    ${mediaQuery.sm`
  flex-direction: row;
`}
  }
`;

export const LogoStyled = styled(Logo)`
  display: block;
  margin-right: 3rem;
  z-index: 1;

  margin-left: -6px;

  ${mediaQuery.sm`
      margin-left: 0;
    `}/* Adjust at mlaptop or laptopL */
`;

export const FooterMenu = styled.ul`
  width: 80%;

  &.social {
    display: flex;
    width: 20%;
    justify-content: stretch;
    gap: 12px;
    a {
      font-size: 28px;
    }
    svg {
      color: var(--color__white);
      opacity: 0.5;
      transition: opacity 0.5s ease;
      &:hover {
        opacity: 1;
      }

      &:active {
        opacity: 0.7;
      }
    }
    margin-top: 24px;
    ${mediaQuery.sm`
      margin-top: 0;
    `}
  }
`;

export const Powered = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  max-width: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #252938;
  .R {
    opacity: 0.5;
    transition: opacity 0.5s ease;
  }

  g {
    transition: opacity 0.5s ease;
  }
  svg {
    cursor: pointer;
    width: 200px;
    height: 65px;

    &:hover {
      .R {
        opacity: 1;
      }

      g {
        opacity: 0.6 !important;
      }
    }
  }
`;
