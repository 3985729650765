import React from "react";
import { LinkedIn } from "../../Contact/Contact.styled";

import { SocialIconsStyled, Youtube } from "./SocialIcons.styled";

const SocialIcons = ({ isService, isBlog }) => (
  <SocialIconsStyled>
    <ul className="social">
      <li>
        <LinkedIn
          href="https://www.linkedin.com/company/gomez-monux/"
          target="_blank"
          rel="noopener noreferrer"
          isService={isService}
          isBlog={isBlog}
          isHero={true}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="linkedin"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            className="svg-inline--fa fa-linkedin fa-w-14 fa-3x"
          >
            <path
              fill="currentColor"
              d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
              className=""
            ></path>
          </svg>
        </LinkedIn>
      </li>

      <li>
        <Youtube
          href="https://www.youtube.com/channel/UCJ-xZo9ZEdzQCbDQOecwysQ"
          target="_blank"
          rel="noopener noreferrer"
          isService={isService}
          isBlog={isBlog}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="youtube-square"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M186.8 202.1l95.2 54.1-95.2 54.1V202.1zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-42 176.3s0-59.6-7.6-88.2c-4.2-15.8-16.5-28.2-32.2-32.4C337.9 128 224 128 224 128s-113.9 0-142.2 7.7c-15.7 4.2-28 16.6-32.2 32.4-7.6 28.5-7.6 88.2-7.6 88.2s0 59.6 7.6 88.2c4.2 15.8 16.5 27.7 32.2 31.9C110.1 384 224 384 224 384s113.9 0 142.2-7.7c15.7-4.2 28-16.1 32.2-31.9 7.6-28.5 7.6-88.1 7.6-88.1z"
              className=""
            ></path>
          </svg>
        </Youtube>
      </li>
    </ul>
  </SocialIconsStyled>
);

export default SocialIcons;
