import styled, { css } from "styled-components";
import { container } from "../../styles/GlobalStyles";
import { Link } from "gatsby";

import { device } from "../../styles/mixins";

export const HeaderStyles = styled.header`
  font-family: "Raleway", sans-serif !important;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 2;
`;

export const Nav = styled.nav`
  ${container};

  align-items: center;
  display: flex;
  padding: 2rem 0;
`;

export const NavLinks = styled.ul`
  justify-content: center;
  margin-left: auto;
  display: flex;

  > li {
    margin-left: 1rem;
  }

  &.Navigation {
    display: none;
  }
  @media ${device.tablet} {
    &.Navigation {
      display: flex;
    }
  }
`;

const headerColors = {
  servicio: {
    color: "white",
    backgroundColor: "rgba(255,255,255, 0.25)",
    hoverColor: "white",
  },
  home: {
    color: "var(--color__grey)",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    hoverColor: "#5b5f72",
  },
};

export const NavGroupLinks = styled.ul`
  position: absolute;
  top: calc(100% + 1.5rem);
  left: 0;
  background: hsla(0, 0%, 100%, 0.15);
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: var(--borderRadius);

  ${(props) =>
    props.active &&
    css`
      max-height: 100vh;
      opacity: 1;
      -webkit-transition: all 0.6s ease;
      transition: all 0.6s ease;
    `}
`;

export const NavLink = styled(Link)`
  padding: 0.5rem 1.5rem 0.4rem;
  display: block;
  font-weight: 500;
  font-size: 18px;
  transition: all 0.5s ease !important;
  line-height: 1.5;
  color: ${({ isService, isBlog }) =>
    isService || isBlog
      ? headerColors.servicio.color
      : headerColors.home.color};
  text-decoration: none;
  border: 2px solid transparent;
  border-radius: var(--borderRadius);
  cursor: pointer;

  &:hover {
    color: ${({ isService, isBlog }) =>
      isService || isBlog
        ? headerColors.servicio.hoverColor
        : headerColors.home.hoverColor};
    background-color: ${({ isService, isBlog }) =>
      isService || isBlog
        ? headerColors.servicio.backgroundColor
        : headerColors.home.backgroundColor};
    border-color: currentColor;
  }
`;
