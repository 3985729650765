import React from "react";

const Logo = ({ color = "#377d4f", size = 56, className }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 100.000000 100.000000"
    preserveAspectRatio="xMidYMid meet"
    className={className}
  >
    <g
      transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
      fill={color}
      stroke="none"
    >
      <path d="M120 840 l0 -70 42 46 c23 25 63 56 88 69 l45 24 -87 1 -88 0 0 -70z" />
      <path
        d="M580 895 c30 -17 62 -19 77 -4 17 17 29 -15 37 -93 8 -79 -2 -86 -38
    -30 -53 84 -133 132 -220 132 -140 -1 -243 -124 -234 -280 2 -36 12 -82 23
    -104 18 -37 91 -116 107 -116 4 0 8 32 8 70 0 99 -24 140 -81 140 -19 0 -26
    15 -11 24 4 2 49 1 101 -2 l93 -7 55 -100 c30 -55 57 -102 59 -104 2 -2 10 2
    19 9 10 9 15 31 15 71 0 72 -21 99 -76 99 -24 0 -34 4 -32 13 3 9 44 13 146
    15 122 2 142 0 142 -13 0 -8 -9 -15 -21 -15 -48 0 -59 -17 -59 -86 0 -82 -9
    -98 -64 -119 l-45 -17 25 -49 c13 -27 33 -63 44 -81 l18 -31 37 57 c20 31 69
    121 110 200 l75 143 0 146 0 147 -167 0 c-151 -1 -165 -2 -143 -15z"
      />
      <path
        d="M370 462 l0 -80 46 -7 c25 -3 48 -4 51 -2 5 6 -75 154 -88 163 -5 3
    -9 -29 -9 -74z"
      />
      <path
        d="M788 348 c-84 -150 -128 -238 -128 -255 0 -3 45 -3 101 -1 86 2 103
    6 115 22 11 16 14 62 14 213 0 106 -1 193 -3 193 -1 0 -46 -78 -99 -172z"
      />
      <path
        d="M120 290 l0 -200 80 0 c73 0 83 3 105 25 24 23 25 31 25 135 0 94 -2
    110 -16 110 -29 0 -134 62 -164 97 l-30 34 0 -201z"
      />
      <path
        d="M370 239 c0 -144 -1 -143 139 -147 62 -2 111 0 111 5 0 5 -29 63 -65
    129 l-64 119 -61 0 -60 0 0 -106z"
      />
    </g>
  </svg>
);

export default Logo;
