import React from "react";
import Submenu from "./components/Submenu/Submenu";
import { graphql, Link, useStaticQuery } from "gatsby";

import { HeaderStyles, Nav, NavLinks, NavLink } from "./Header.styled";
import Drawer from "./components/Drawer";
import Logo from "../UI/Logo";
const Header = ({ isServicePage, activePage, isBlog }) => {
  const headerItems = [
    {
      url: "/nosotros",
      text: "Sobre nosotros",
    },
    {
      url: "/blog",
      text: "Blog",
    },
    {
      url: "/#contact",
      text: "Contacto",
    },
  ];
  const {
    services: { nodes: services },
  } = useStaticQuery(graphql`
    query {
      services: allSanityService(sort: { fields: order, order: ASC }) {
        nodes {
          order
          title {
            es
          }
          slug {
            current
          }
        }
      }
    }
  `);

  const serviceLinks = services.reduce((accumulator, serviceNode) => {
    return [
      ...accumulator,
      { value: serviceNode.slug.current, label: serviceNode.title.es },
    ];
  }, []);

  return (
    <HeaderStyles>
      <Nav>
        {/* TODO: change to svg */}
        <Link
          className="Logo"
          aria-current="page"
          tabIndex="0"
          aria-label="Navigation"
          role="button"
          to="/"
        >
          <Logo
            size={80}
            color={(activePage === "servicio" && "white") || "#377d4f"}
          />
        </Link>
        <NavLinks className="Navigation">
          <li>
            <Submenu
              isService={activePage === "servicio"}
              isBlog={isBlog}
              services={serviceLinks}
            />
          </li>
          {headerItems.map((item, i) => (
            <li key={i}>
              <NavLink
                to={item.url}
                aria-label="Navigation"
                role="button"
                isService={activePage === "servicio"}
                isBlog={isBlog}
              >
                {item.text}
              </NavLink>
            </li>
          ))}
        </NavLinks>
        <Drawer links={headerItems} serviceLinks={serviceLinks} />
      </Nav>
    </HeaderStyles>
  );
};

export default Header;
