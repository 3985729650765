import styled from "styled-components";

export const Youtube = styled.a`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
  color: ${({ isService, isBlog }) =>
    isService || isBlog ? "#c7edc4" : "var(--color__grey)"};
  cursor: pointer;
  width: fit-content;

  &,
  path {
    transition: all 0.5s ease;
  }

  &:hover {
    transform: scale(0.9);
  }
`;

export const SocialIconsStyled = styled.nav`
  .social {
    margin-top: 26px;
    display: flex;
    gap: 1.3rem;
    svg {
      width: 40px;
      height: 40px;
    }

    li {
      /* border: solid 2px var(--color__grey);
      background-color: var(--color__grey);
      border-radius: 50%;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #5c5c5c;
      border-color: #5c5c5c;
      transition: all 0.5s ease; */

      &:hover {
        /* background-color: #26272e;
        border-color: #26272e; */
      }

      svg {
        path,
        polyline,
        polygon,
        rect {
          /* color: white; */
        }
      }
    }

    a {
      /* color: white;
      display: flex;
      justify-content: center;
      align-items: center; */

      &:hover {
        /* color: white;
        transition: all 0.5s ease; */
      }
    }
  }
`;
